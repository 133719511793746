import imageCompression from 'browser-image-compression';
import { MMS_RECOMMENDED_IMG_SIZE_MB } from '../../../utils/settings';

import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../../redux/actions/BlueprintAPI';

export const uploadSMSImage = async (file, signal) => {
  try {
    const sessionToken = await localStorage.getItem('sToken');
    const payload = {
      sessionToken,
      contentType: file.type,
    };
    const response = await BlueprintAPI(
      APIServiceConstants.UPLOADS,
      'signed-url',
      payload,
    );

    return fetch(response.data.s3PutObjectUrl, {
      method: 'PUT',
      signal,
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
      body: file,
    });
  } catch (error) {
    console.error('Error uploading', error);
    throw error;
  }
};

export const compressImage = async (file) => {
  if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
    return null;
  }

  const options = {
    maxSizeMB: MMS_RECOMMENDED_IMG_SIZE_MB,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('Error while compressing the uploaded image', error);
  }

  return null;
};
